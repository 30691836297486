import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DealerType } from "../../../../definitions/campaign";
import {
  reportCampaignShop,
  reportOrderDetailShop,
} from "../../../../datasource/CampaignDatasource";
import { Col, Row, Table } from "antd";
import Descriptions from "../../../../components/Description/Descriptions";
import Text from "../../../../components/Text/Text";
import { FlexCol, FlexRow } from "../../../../components/Container/Container";
import { numberFormatter } from "../../../../utility/Formatter";

const TabProduct: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(1);

  useEffect(() => {
    if (!loading) fetchData();
  }, [page]);

  const fetchData = async () => {
    setLoading(true);
    const type = pathSplit[3];
    const id = pathSplit[4];
    await reportOrderDetailShop({
      customerCompanyId: parseInt(id),
      userType: type?.toUpperCase(),
      page,
      take: 10,
    })
      .then((res) => {
        console.log(res);
        setData(res.dataResult);
        setCount(res.totalQty);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "สินค้า",
      dataIndex: "productName",
      key: "productName",
      render: (value, row) => {
        return (
          <FlexRow>
            <FlexCol>
              <img
                src={row?.productImage}
                style={{ width: 50, height: 50, objectFit: "contain" }}
              />
            </FlexCol>
            <FlexCol>
              <Text>{value}</Text>
              <Text level={6} color='Text3'>
                {row?.productCommonName}
              </Text>
            </FlexCol>
          </FlexRow>
        );
      },
    },
    {
      title: "ขนาด",
      dataIndex: "saleUom",
      key: "saleUom",
      render: (value, row) => {
        return (
          <FlexCol>
            <Text>{value}</Text>
            <Text level={6} color='Text3'>
              {row?.unitName}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "ปริมาณที่สั่งซื้อ",
      dataIndex: "sumQuantity",
      key: "sumQuantity",
      render: (value, row) => {
        return (
          <FlexCol>
            <Text>{numberFormatter(value, 2)}</Text>
            <Text level={6} color='Text3'>
              ตัน
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "ยอดแต้ม",
      dataIndex: "sumPoint",
      key: "sumPoint",
      render: (value) => {
        return (
          <FlexCol>
            <Text>{numberFormatter(value, 2)}</Text>
            <Text level={6} color='Text3'>
              แต้ม
            </Text>
          </FlexCol>
        );
      },
    },
  ];
  return (
    <>
      <Row>
        <Col span={12}>
          <Descriptions label='ปริมาณที่สั่งซื้อทั้งหมด' value={count + " ตัน"} noColon />
        </Col>
      </Row>
      <Table
        className='rounded-lg'
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={data ? data[0]?.product : []}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          current: page,
          total: count,
          onChange: (p) => setPage(p),
          showSizeChanger: false,
        }}
        size='large'
        tableLayout='fixed'
        loading={loading}
      />
    </>
  );
};

export default TabProduct;
