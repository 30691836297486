import React, { useEffect, useMemo, useState } from "react";
import { CardContainer } from "../../../components/Card/CardContainer";
import { useForm } from "antd/lib/form/Form";
import { Col, Modal, Row, Spin, message } from "antd";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import StepAntd from "../../../components/StepAntd/StepAntd";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Buttons from "../../../components/Button/Button";
import { StepOne } from "./Steps/Step1";
import { StepTwo } from "./Steps/Step2";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createCampaign,
  getCampaignById,
  updateCampaign,
  updateCampaignFile,
} from "../../../datasource/CampaignDatasource";
import { Campaign } from "../../../entities/CampaignEntity";
import { useEffectOnce } from "react-use";
import { useRecoilState } from "recoil";
import { campaignState, step2State } from "../../../store/createCampaign";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { FlexCol } from "../../../components/Container/Container";
import color from "../../../resource/color";
import Text from "../../../components/Text/Text";
import { CampaignProgramType } from "../../../definitions/campaign";

export const CreateCampaignPoint: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company, firstname, lastname } = userProfile;

  const navigate = useNavigate();
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const id = pathSplit[3];
  const isEditing = !!id;

  const [current, setCurrent] = useState(0);

  const [form1] = useForm();
  const [form2] = useForm();
  const [program, setProgram] = useState<any>([]);
  const [detailData, setDetailData] = useState<any>();

  const [fileImage, _setFileImage] = useState<any>();
  const [fileDoc, _setFileDoc] = useState<any>();
  const [imageUrl, setImgUrl] = useState<string>();
  const [docUrl, setDocUrl] = useState<string>();
  const [fileEditHistory, setFileEditHistoy] = useState(new Set());
  const fileKeys = {
    IMAGE: "img",
    DOC: "doc",
  };
  const addFileEditHistory = (s: string) => {
    const set = new Set(fileEditHistory);
    set.add(s);
    setFileEditHistoy(set);
  };
  const setFileImage = (f: any) => {
    _setFileImage(f);
    addFileEditHistory(fileKeys.IMAGE);
  };
  const setFileDoc = (f: any) => {
    console.log("new doc", f);
    _setFileDoc(f);
    addFileEditHistory(fileKeys.DOC);
  };

  const [isCreating, setCreating] = useState(false);
  const [isDone, setDone] = useState(false);

  const [loading, setLoading] = useState(false);
  const [oldCampaignData, setOldCampaignData] = useState<Campaign>();

  const [state, setState] = useRecoilState(campaignState);
  const [step2state, setStep2State] = useRecoilState(step2State);

  useEffectOnce(() => {
    if (isEditing) fetchCampaign();
  });

  const fetchCampaign = async () => {
    setLoading(true);
    const id = pathSplit[3];
    await getCampaignById(id)
      .then((res) => {
        if (res?.campaignId && res.campaignId === id) {
          res = {
            ...res,
            startDate: dayjs(res.startDate),
            endDate: dayjs(res.endDate),
            startTime: dayjs(res.startDate),
            endTime: dayjs(res.endDate),
          };
          setOldCampaignData(res);
          form1.setFieldsValue(res);
          setProgram(
            res.campaignPrograms?.map((pg) => ({
              ...pg,
              stores: pg.campaignProgramDealers?.map((s) => ({
                ...s,
                customerCompanyId: s.dealerId,
                customerNo: s.dealerCode,
                customerName: s.dealerName,
                zone: s.dealerZone,
              })),
              products: pg.campaignProgramProducts,
            })),
          );

          if (res.imageFilename) setImgUrl(res.imageFilename);
          if (res.documentFilename) setDocUrl(res.documentFilename);

          const newPointData = {};
          res.campaignPrograms?.forEach((pg, i) => {
            pg?.campaignProgramProducts?.forEach(
              (p) => (newPointData[`point-${i}-${p?.productId}`] = p.programPoints),
            );
          });
          setState({ ...state, pointData: newPointData });
          form2.setFieldsValue(newPointData);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderStep = () => {
    switch (current) {
      case 0: {
        return (
          <StepOne
            form={form1}
            fileImage={fileImage}
            setFileImage={setFileImage}
            fileDoc={fileDoc}
            setFileDoc={setFileDoc}
            imageUrl={imageUrl}
            setImgUrl={setImgUrl}
            docUrl={docUrl}
            setDocUrl={setDocUrl}
          />
        );
      }
      case 1: {
        return <StepTwo form={form2} programs={program} setPrograms={setProgram} />;
      }
    }
  };

  const onNext = () => {
    if (current === 0) {
      form1
        .validateFields()
        .then((values) => {
          setDetailData(values);
          setCurrent(1);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      form2
        .validateFields()
        .then((values) => {
          //check if no store or product
          let productError = [...step2state.errorProductTabs];
          let storeError = [...step2state.errorStoreTabs];
          if (productError.length < program.length) {
            productError = program.map((p, i) => productError[i] || false);
          }
          if (storeError.length < program.length) {
            storeError = program.map((p, i) => storeError[i] || false);
          }
          program.forEach((pg, i) => {
            const { products, stores } = pg;
            if (!products || !products.length) {
              productError[i] = true;
            }
            if (!stores || !stores.length) {
              storeError[i] = true;
            }
          });
          setStep2State({
            ...step2state,
            errorProductTabs: productError,
            errorStoreTabs: storeError,
          });

          // check if no points
          if (
            program.length <= 0 ||
            !program.every(
              (pg, i) => !!pg?.products?.every((pd) => values[`point-${i}-${pd.productId}`]),
            )
          ) {
            return message.error("โปรดตรวจสอบข้อมูลให้ครบถ้วนก่อนบันทึก");
          }

          onSubmit();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onSubmit = async () => {
    const { startDate, startTime, endDate, endTime, isRebate } = detailData;

    const { pointData } = state;

    const submitData = {
      ...detailData,
      campaignId: id,
      company,
      startDate:
        startDate && startTime
          ? dayjs(
              `${startDate.format("YYYY-MM-DD")} ${startTime.format("HH:mm")}:00.000`,
            ).toISOString()
          : undefined,
      endDate:
        endDate && endTime
          ? dayjs(`${endDate.format("YYYY-MM-DD")} ${endTime.format("HH:mm")}:00.000`).toISOString()
          : undefined,

      startTime: undefined,
      endTime: undefined,
      campaignCondition: state.condition,
      objective: isRebate ? ["REBATE"] : [],
      createBy: isEditing ? undefined : `${firstname} ${lastname}`,
      updateBy: `${firstname} ${lastname}`,
      campaignPrograms: program.map((pg, i) => {
        const {
          campaignProgramId,
          campaignProgramCondition,
          campaignProgramName,
          campaignProgramType,
          products,
          stores,
          updateBy,
        } = pg;
        return {
          ...pg,
          campaignProgramName,
          campaignProgramType,
          campaignProgramCondition,
          updateBy: updateBy || `${firstname} ${lastname}`,
          campaignProgramDealers: stores.map((s) => ({
            ...s,
            dealerId: s.customerCompanyId,
            dealerCode: s.customerNo,
            dealerName: s.customerName,
            dealerZone: s.zone,
            dealerType:
              campaignProgramType === CampaignProgramType.ONLY_SUB_DEALER ? "SUB_DEALER" : "DEALER",
          })),
          campaignProgramProducts: products.map((p) => {
            // console.log(p.productId, pointData[`point-${i}-${p.productId}`]);
            return {
              ...p,
              productCompany: p.company,
              programPoints:
                pointData && pointData[`point-${i}-${p.productId}`]
                  ? pointData[`point-${i}-${p.productId}`]
                  : 0,
            };
          }),
        };
      }),
    };

    // console.log(submitData);
    // return;

    const callback = (res: any) => {
      console.log("ID: ", res?.campaignId);
      const { campaignId } = res;
      const onDone = () => {
        form1.resetFields();
        form2.resetFields();
        setProgram([]);
        setDetailData(undefined);
        setDone(true);
        setTimeout(() => {
          navigate("/crm/campaignPoint");
          window.location.reload();
        }, 2000);
        setTimeout(() => {
          setDone(false);
        }, 2000);
      };

      if (campaignId) {
        if (fileEditHistory.size <= 0) {
          onDone();
        } else {
          const formData = new FormData();
          formData.append("campaignId", campaignId);
          formData.append("updateBy", `${firstname} ${lastname}`);
          if (fileEditHistory.has(fileKeys.IMAGE)) {
            formData.append("imageAction", fileImage ? "UPLOAD" : "DELETE");
            formData.append("image", fileImage);
          } else {
            formData.append("imageAction", "NO_ACTION");
          }
          if (fileEditHistory.has(fileKeys.DOC)) {
            formData.append("documentAction", fileDoc?.originFileObj ? "UPLOAD" : "DELETE");
            formData.append("document", fileDoc?.originFileObj);
          } else {
            formData.append("documentAction", "NO_ACTION");
          }
          for (const pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1]);
          }
          updateCampaignFile(formData)
            .then((res) => {
              onDone();
              console.log("fileUploadRes", res);
            })
            .catch((err) => {
              console.log("updateCampaignFile", err);
              throw err;
            });
        }
      } else {
        message.error("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
        console.log("ERROR: Not found campaign ID in response body");
      }
    };

    if (!isEditing) {
      await createCampaign(submitData)
        .then(callback)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setCreating(false);
        });
    } else {
      await updateCampaign(submitData)
        .then(callback)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setCreating(false);
        });
    }
  };

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={`รายการแคมเปญพอยท์`}
        showBack
        extra={
          <StepAntd
            current={current}
            items={[
              {
                title: "รายละเอียดแคมเปญ",
              },
              {
                title: "เพิ่มพอยท์โปรแกรม",
              },
            ]}
          />
        }
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการแคมเปญพอยท์", path: "/crm/campaignPoint" },
              {
                text: "สร้างแคมเปญพอยท์",
                path: window.location.pathname,
              },
            ]}
          />
        }
      />
    );
  };

  const footer = () => {
    return (
      <Row justify='space-between' gutter={12}>
        <Col xl={3} sm={6}>
          {current > 0 && (
            <Buttons
              typeButton='primary-light'
              title='ย้อนกลับ'
              onClick={() => setCurrent(current - 1)}
            />
          )}
        </Col>
        <Col xl={15} sm={6}></Col>
        <Col xl={3} sm={6}>
          <Buttons
            typeButton='primary'
            title={current === 1 ? "บันทึก" : "ถัดไป"}
            onClick={onNext}
            disabled={isCreating}
          />
        </Col>
      </Row>
    );
  };

  const LoadingModal = () => {
    return (
      <Modal open={isCreating || isDone} footer={null} width={220} closable={false}>
        <FlexCol align='space-around' justify='center' style={{ width: 172, height: 172 }}>
          {isDone ? (
            <CheckCircleTwoTone twoToneColor={color.success} style={{ fontSize: 36 }} />
          ) : (
            <Spin size='large' />
          )}
          <br />
          <Text level={4} align='center'>
            {isDone ? (
              <>
                สร้าง
                <br />
                รายการแคมเปญพอยท์
                <br />
                สำเร็จ
              </>
            ) : (
              <>
                กำลังสร้าง
                <br />
                รายการแคมเปญพอยท์
              </>
            )}
          </Text>
        </FlexCol>
      </Modal>
    );
  };

  return (
    <CardContainer>
      <PageTitle />
      {renderStep()}
      <br />
      {footer()}
      <LoadingModal />
    </CardContainer>
  );
};
