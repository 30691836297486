import {
  DeleteOutlined,
  DownOutlined,
  DownloadOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import React, { ReactNode, useEffect, useState } from "react";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Tabs from "../../../components/AntdTabs/AntdTabs";
import { CardContainer } from "../../../components/Card/CardContainer";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/th";
import Text from "../../../components/Text/Text";
import { Avatar, Badge, Col, Collapse, Divider, Form, Image, Input, Row, Table, Tag } from "antd";
import image from "../../../resource/image";
import styled from "styled-components";
import { Campaign, CampaignProgram } from "../../../entities/CampaignEntity";
import color from "../../../resource/color";
import { FileItem } from "../../OrderManagement/orderComponent/fileItem";
import { getCampaignById } from "../../../datasource/CampaignDatasource";
import {
  campaignProgramConditionLabel,
  campaignProgramTypeLabel,
  dealerTypeName,
} from "../../../definitions/campaign";
import Button from "../../../components/Button/Button";
import { StoreEntity } from "../../../entities/StoreEntity";
import Select from "../../../components/Select/Select";
import { useEffectOnce } from "react-use";
import { zoneDatasource } from "../../../datasource/ZoneDatasource";
import TableContainer from "../../../components/Table/TableContainer";
import { ProductEntity } from "../../../entities/PoductEntity";
import icon from "../../../resource/icon";
import { CollapseContainer, ProgramTabsContainer, TabBody, TabHeader } from "./ProgramComponents";
import { campaignState, tabId } from "../../../store/createCampaign";
import { useRecoilState } from "recoil";
import { convertBuddhistYear } from "../../../utility/Formatter";

const Container = styled.div`
  margin: 32px 0px 10px 0px;
`;

const Desc = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <Row align='middle' style={{ padding: "8px 0px" }}>
      <Col xl={6} sm={8}>
        <Text level={5} color='Text3'>
          {label}
        </Text>
      </Col>
      <Col xl={18} sm={16}>
        <Text level={5} color='Text1'>
          {value || "-"}
        </Text>
      </Col>
    </Row>
  );
};

const TrackingItem = ({
  title,
  number,
  unit,
}: {
  title: ReactNode;
  number: number;
  unit: string;
}) => {
  return (
    <div
      style={{ padding: 16, background: "#FAFAFA", border: "1px solid #E5EAEE", borderRadius: 4 }}
    >
      <Text fontWeight={700}>{title}</Text>
      <br />
      <Text level={2} fontWeight={700}>
        {number}
      </Text>
      &nbsp;&nbsp;<Text fontWeight={700}>{unit}</Text>
    </div>
  );
};

export const CampaignDetail: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState<Campaign>();

  const [state, setState] = useRecoilState(campaignState);

  const [zone, setZone] = useState<{ label: string; value: string; key: string }[]>([]);

  useEffectOnce(() => {
    if (zone.length <= 0) {
      getZoneByCompany();
    }
  });

  const getZoneByCompany = async () => {
    const res = await zoneDatasource.getAllZoneByCompany(company);
    const data = res.map((item: any) => {
      return {
        label: item.zoneName,
        value: item.zoneName,
        key: item.zoneId,
      };
    });
    setZone(data);
  };

  useEffect(() => {
    if (!loading) fetchCampaign();
  }, []);

  const fetchCampaign = async () => {
    setLoading(true);
    const id = pathSplit[3];
    await getCampaignById(id)
      .then((res) => {
        setCampaignData(res);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={"รายละเอียดแคมเปญพอยท์"}
        showBack
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการแคมเปญพอยท์", path: "/crm/campaignPoint" },
              {
                text: "รายละเอียดแคมเปญพอยท์",
                path: window.location.pathname,
              },
            ]}
          />
        }
        extra={
          <Button
            title='แก้ไขแคมเปญ'
            icon={<EditOutlined />}
            onClick={() => navigate(`/crm/createCampaignPoint/${pathSplit[3]}`)}
          />
        }
      />
    );
  };

  const TabDetail = () => {
    const details = [
      {
        label: "สถานะ",
        value: (
          <Tag color={campaignData?.isEnabled ? color.success : color.Disable}>
            {campaignData?.isEnabled ? "เปิดใช้งาน" : "ปิดใช้งาน"}
          </Tag>
        ),
      },
      {
        label: "รหัสแคมเปญ",
        value: campaignData?.campaignCode,
      },
      {
        label: "ชื่อแคมเปญ",
        value: campaignData?.campaignName,
      },
      {
        label: "ระยะเวลาแคมเปญ",
        value: `${
          campaignData?.startDate
            ? convertBuddhistYear.toBuddhistYear(
                moment(campaignData?.startDate),
                "DD MMM YYYY, HH:mm น.",
              )
            : ""
        } - ${
          campaignData?.endDate
            ? convertBuddhistYear.toBuddhistYear(
                moment(campaignData?.endDate),
                "DD MMM YYYY, HH:mm น.",
              )
            : ""
        }`,
      },
      {
        label: "กติกาและเงื่อนไขแคมเปญ",
        value: (
          <div
            className='view ql-editor'
            style={{
              display: "block",
              wordBreak: "break-word",
              wordWrap: "break-word",
            }}
            dangerouslySetInnerHTML={{ __html: campaignData?.campaignCondition || "" }}
          ></div>
        ),
      },
      {
        label: "เป้าหมายในแคมเปญ",
        value: "เพื่อ Rebate",
      },
    ];
    return (
      <>
        <div style={{ padding: 16 }}>
          <Text fontWeight={700}>ข้อมูลแคมเปญ</Text>
          <Row style={{ padding: 16 }}>
            <Image
              src={campaignData?.imageFilename || image.emptyProductBrand}
              style={{
                width: "234",
                height: "136px",
                objectFit: "contain",
              }}
            />
          </Row>
          <Container>
            {details.map((p, i) => (
              <Desc {...p} key={i} />
            ))}
          </Container>
          {campaignData?.documentFilename && (
            <Row>
              <Col span={12}>
                <br />
                <Text fontWeight={700}>ไฟล์เอกสารอ้างอิง</Text>
                <FileItem
                  file={{ filePath: campaignData?.documentFilename }}
                  image={
                    <img src={image.pdfIcon} style={{ width: 24, height: 30, marginRight: 12 }} />
                  }
                  viewButton={
                    <Button
                      title='ดูรายละเอียด'
                      typeButton='primary'
                      icon={<img src={icon.viewFile} style={{ width: 16, height: 16 }} />}
                      style={{ width: 160 }}
                    />
                  }
                />
              </Col>
            </Row>
          )}
          <Divider />
          <>
            {campaignData?.campaignPrograms.map((program: CampaignProgram, i) => {
              const prog = {
                ...program,
                products: program.campaignProgramProducts,
                stores: program.campaignProgramDealers,
              };
              return (
                <>
                  <CollapseContainer>
                    <Collapse
                      collapsible='icon'
                      expandIconPosition='end'
                      expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                    >
                      <Collapse.Panel
                        header={<ProgramPanelHeader i={i} program={prog} />}
                        key={prog.campaignProgramId}
                      >
                        <ProgramPanelBody i={i} program={prog} />
                      </Collapse.Panel>
                    </Collapse>
                  </CollapseContainer>
                </>
              );
            })}
          </>
        </div>
      </>
    );
  };

  const TabTracking = () => {
    const details = [
      {
        label: "รหัสแคมเปญ",
        value: campaignData?.campaignCode,
      },
      {
        label: "ชื่อแคมเปญ",
        value: campaignData?.campaignName,
      },
      {
        label: "ระยะเวลาแคมเปญ",
        value: `${
          campaignData?.startDate ? moment(campaignData?.startDate).format("YYYY-MM-DD") : ""
        } - ${
          campaignData?.endDate ? moment(campaignData?.endDate).format("YYYY-MM-DD") : ""
        } (ผ่านมาแล้ว 37 วัน)`,
      },
      {
        label: "เป้าหมายในแคมเปญ",
        value: "เพื่อ Rebate, เพื่อแลกของรางวัล",
      },
      {
        label: "ร้านค้าทั้งหมด",
        value: "92 ร้าน",
      },
    ];
    return (
      <>
        <div style={{ padding: 16 }}>
          <Row justify='space-between'>
            <Col span={12}>
              <Text fontWeight={700}>ภาพรวมแคมเปญ</Text>
            </Col>
            <Col span={4}>
              <Button
                title='ดาวน์โหลดแคมเปญ'
                icon={<DownloadOutlined />}
                typeButton='primary-light'
              />
            </Col>
          </Row>
          <Container>
            {details.map((p, i) => (
              <Desc {...p} key={i} />
            ))}
          </Container>
        </div>
        <Row gutter={16}>
          <Col span={8}>
            <TrackingItem title='คำสั่งซื้อทั้งหมด (ยอดขน)' number={2000000} unit='รายการ' />
          </Col>
          <Col span={8}>
            <TrackingItem title='คำสั่งซื้อทั้งหมด (ยอดขน)' number={2000000} unit='รายการ' />
          </Col>
          <Col span={8}>
            <TrackingItem title='คำสั่งซื้อทั้งหมด (ยอดขน)' number={2000000} unit='รายการ' />
          </Col>
        </Row>
        <Divider />
        {/* <div style={{ padding: 16 }}>
          <Row justify='space-between'>
            <Text fontWeight={700}>ภาพรวมแคมเปญ</Text>
          </Row>
        </div> */}
      </>
    );
  };

  const tabsItems = [
    {
      key: "1",
      label: "รายละเอียด",
      children: <TabDetail />,
    },
    {
      key: "2",
      label: "ติดตามแคมเปญ",
      children: <TabTracking />,
    },
  ];

  const ProgramPanelHeader = ({ i, program }: { i: number; program: CampaignProgram }) => {
    return (
      <Row style={{ padding: "24px 32px" }}>
        <Col span={12}>
          <Text color='Text3' level={6}>
            ชื่อพอยท์โปรแกรม
          </Text>
          <br />
          <Text color='Text1' level={5} fontWeight={700}>
            {program.campaignProgramName}
          </Text>
        </Col>
        <Col span={6}>
          <Text color='Text3' level={6}>
            รูปแบบการให้แต้ม
          </Text>
          <br />
          <Text color='Text1' level={5} fontWeight={700}>
            {campaignProgramTypeLabel[program.campaignProgramType]}
          </Text>
        </Col>
        <Col span={6}>
          <Text color='Text3' level={6}>
            เงื่อนไขการได้รับแต้ม
          </Text>
          <br />
          <Text color='Text1' level={5} fontWeight={700}>
            {campaignProgramConditionLabel[program.campaignProgramCondition]}
          </Text>
        </Col>
      </Row>
    );
  };

  const ProgramPanelBody = ({ i, program }: { i: number; program: CampaignProgram }) => {
    const [activeTabId, setActiveTabId] = useState<string>(tabId.store);

    const StoreTab = () => {
      const [searchStoreForm] = Form.useForm();

      const [filteredStore, setFilteredStore] = useState<StoreEntity[]>(program?.stores || []);

      const onSearchStore = (values) => {
        if (program?.stores) {
          setFilteredStore(
            program?.stores
              .filter((s) => {
                if (values.search) {
                  return s?.customerName?.indexOf(values.search) > -1;
                }
                return true;
              })
              .filter((s) => {
                if (values.zone) {
                  return s?.zone === values.zone;
                }
                return true;
              }),
          );
        } else {
          setFilteredStore([]);
        }
      };

      const columns = [
        {
          title: "รหัสร้านค้า",
          dataIndex: "dealerId",
          key: "dealerId",
          render: (value: any, row: any, index: number) => {
            return {
              children: <Text>{value}</Text>,
            };
          },
        },
        {
          title: "ชื่อร้านค้า",
          dataIndex: "dealerName",
          key: "dealerName",
          render: (value: any, row: any, index: number) => {
            return {
              children: (
                <>
                  <Text>{value}</Text>
                  <br />
                  <Text level={6} color='Text3'>
                    {row?.customer?.taxNo}
                  </Text>
                </>
              ),
            };
          },
        },
        {
          title: "ประเภทคู่ค้า",
          dataIndex: "dealerType",
          key: "dealerType",
          render: (value: any, row: any, index: number) => {
            console.log(row);
            return {
              children: <Text level={6}>{dealerTypeName[value]}</Text>,
            };
          },
        },
        {
          title: "เขตการขาย",
          dataIndex: "dealerZone",
          key: "dealerZone",
          render: (value: any, row: any, index: number) => {
            return {
              children: <Text>{value}</Text>,
            };
          },
        },
      ];
      return (
        <>
          <Form form={searchStoreForm} onValuesChange={onSearchStore}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item name='search'>
                  <Input placeholder='รหัส / ชื่อร้านค้า' suffix={<SearchOutlined />} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name='zone'>
                  <Select placeholder='เขตทั้งหมด' data={zone} style={{ width: 150 }} />
                </Form.Item>
              </Col>
              <Col span={10} />
            </Row>
          </Form>
          <br />
          <TableContainer>
            <Table
              columns={columns}
              dataSource={filteredStore || program.stores}
              scroll={{ y: 600 }}
            />
          </TableContainer>
        </>
      );
    };

    const ProductTab = () => {
      const [searchProductForm] = Form.useForm();

      const [filteredProd, setFilteredProd] = useState<ProductEntity[]>(program?.products || []);

      const onSearchProduct = (values) => {
        if (program?.products) {
          setFilteredProd(
            program?.products.filter((s) => {
              if (values.search) {
                return s?.productName?.indexOf(values.search) > -1;
              }
              return true;
            }),
          );
        } else {
          setFilteredProd([]);
        }
      };

      const columns = [
        {
          title: "ชื่อสินค้า",
          dataIndex: "name",
          key: "productName",
          width: "40%",
          render: (value: any, row: any, index: number) => {
            return {
              children: (
                <Row gutter={8} style={{ padding: "12px 0px" }}>
                  <Col>
                    <Avatar
                      src={row.productImage ? row.productImage : image.product_no_image}
                      size={70}
                      shape='square'
                      onError={() => false}
                    />
                  </Col>
                  <Col>
                    <Text>{row.productName}</Text>
                    <br />
                    <Text level={6}>{row.commonName}</Text>
                    <br />
                    {company !== "ICPL" ? (
                      <Text level={6} color='Text3'>
                        Product Group : {row.productGroup || "-"}
                      </Text>
                    ) : (
                      <Text level={6} color='Text3'>
                        Stragery Group : {row.productStrategy || "-"}
                      </Text>
                    )}
                  </Col>
                </Row>
              ),
            };
          },
        },
        {
          title: "",
          dataIndex: "name",
          key: "productName",
          width: "20%",
          render: (value: any, row: any, index: number) => {
            return (
              <Row>
                <Col>
                  <Text>{row.company}</Text>
                  <br />
                  <Text level={6}>{row.commonName}</Text>
                </Col>
              </Row>
            );
          },
        },
        {
          title: "แต้มที่ได้รับ",
          dataIndex: "productId",
          key: "point",
          width: "42%",
          render: (productId: any, row: any) => {
            return (
              <Row gutter={16} align='middle'>
                <Col span={15}>
                  <Text level={5}>
                    <img src={icon.star} style={{ width: "16px" }} />
                    &nbsp;แต้มทีได้รับ
                  </Text>
                  <Input
                    suffix={<Text level={5}>แต้ม</Text>}
                    disabled
                    value={row.programPoints}
                    size='large'
                  />
                </Col>
                <Col span={9}>
                  <Text level={5}>ต่อหน่วย SKU</Text>
                  <Input disabled value={row.saleUOMTH} size='large' />
                </Col>
              </Row>
            );
          },
        },
      ];
      return (
        <>
          <Form form={searchProductForm} onValuesChange={onSearchProduct}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item name='search'>
                  <Input placeholder='รหัส / ชื่อร้านค้า' suffix={<SearchOutlined />} />
                </Form.Item>
              </Col>
              <Col span={18} />
            </Row>
          </Form>
          <br />
          <TableContainer>
            <Table columns={columns} dataSource={program.products} scroll={{ y: 600 }} />
          </TableContainer>
        </>
      );
    };

    return (
      <ProgramTabsContainer style={{ background: color.background4 }}>
        <Row gutter={4}>
          <TabHeader
            itemId={tabId.store}
            title='ร้านค้า'
            count={program.stores?.length || 0}
            isActive={activeTabId === tabId.store}
            setActive={setActiveTabId}
          />
          <TabHeader
            itemId={tabId.product}
            title='สินค้า'
            count={program.products?.length || 0}
            isActive={activeTabId === tabId.product}
            setActive={setActiveTabId}
          />
        </Row>
        <TabBody>{activeTabId === tabId.store ? <StoreTab /> : <ProductTab />}</TabBody>
      </ProgramTabsContainer>
    );
  };

  return (
    <>
      <CardContainer>
        <PageTitle />
        <br />
        <Tabs data={tabsItems} />
      </CardContainer>
    </>
  );
};
