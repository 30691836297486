import color from "../resource/color";

export const MAP_STATUS = [
  { key: 1, value: "WAIT_APPROVE", label: "รออนุมัติ" },
  { key: 2, value: "APPROVE", label: "อนุมัติ" },
  { key: 3, value: "REJECT", label: "ไม่อนุมัติ" },
  { key: 4, value: "CANCEL", label: "ยกเลิก" },
];
export const MAP_STATUS_COLOR: any = {
  WAIT_APPROVE: color["warning"],
  APPROVE: color["success"],
  REJECT: color["error"],
  CANCEL: color["placeholder"],
};
export const MAP_STATUS_WORD: any = {
  WAIT_APPROVE: "รออนุมัติ",
  APPROVE: "อนุมัติ",
  REJECT: "ไม่อนุมัติ",
  CANCEL: "ยกเลิก",
};
export const MAP_USER_TYPE: any = {
  SUB_DEALER: "Sub-Dealer",
  DEALER: "Dealer",
  ADMIN: "Admin",
};
