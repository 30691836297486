import React from "react";
import { Col } from "antd";
import { color } from "../../../resource";
import styled from "styled-components";
import Text from "../../../components/Text/Text";
import { FlexRow } from "../../../components/Container/Container";

export const AddingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 48px 50px;

  cursor: pointer;

  background: ${color["background1"]};
  border: 1px dashed ${color["primary"]};
  border-radius: 12px;
`;

export const ProgramTabsContainer = styled.div`
  .ant-collapse-content-box {
    background: ${color["background4"]} !important;
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    display: table-row !important;
  }

  width: 100%;
`;

export const CollapseContainer = styled.div`
  .ant-collapse-content-box {
    background: ${color.background4};
  }
`;

export const TabHeader = ({
  itemId,
  title,
  count,
  isActive,
  setActive,
}: {
  itemId: string;
  title: string;
  count: number;
  isActive: boolean;
  setActive: (id: string) => void;
}) => {
  return (
    <Col span={12} onClick={() => setActive(itemId)}>
      <FlexRow
        align='center'
        justify='center'
        style={{
          backgroundColor: isActive ? "white" : "#F5F7FA",
          width: "100%",
          height: 58,
          borderRadius: "6px 6px 0px 0px",
          cursor: "pointer",
        }}
      >
        <Text level={3} color={isActive ? "primary" : "Text1"}>
          {title}&nbsp;({count})
        </Text>
      </FlexRow>
    </Col>
  );
};

export const TabBody = styled.div`
  padding: 20px 12px 12px;
  background: white;
  border-radius: 0 0 6px 6px;
`;
